import _ from "lodash";
import axios, { AxiosRequestConfig } from "axios";
import BaseService from "services/baseService";
import AuthService from "./authService";
import FormError from "./formError";
import { ApiErrorCode } from "services/types";

type RequestConfig = AxiosRequestConfig & { needAuth?: boolean };

type ApiError = {
  message: string;
  error: ApiErrorCode;
  description: string;
  data: object;
};

class FetchService extends BaseService {
  setHeader(needAuth: boolean, currHheaders?: any) {
    const emptyHeader = { headers: {} };
    let header = needAuth ? AuthService.getAuthHeader() : emptyHeader;

    if (!header) header = emptyHeader;

    if(currHheaders) {
      return {...currHheaders, ...header.headers };
    }
    return header.headers;
  }

  post<T>({ needAuth = true, ...config }: RequestConfig) {
    const headers = this.setHeader(needAuth, config.headers);
    console.log('Headers=', headers);
    return FetchService.operation<T>({
      method: "post",
      headers: headers,
      withCredentials: true,
      ...config,
    });
  }

  get<T>({ data: params = {}, needAuth = true, ...config }: RequestConfig) {
    return FetchService.operation<T>({
      method: "get",
      headers: this.setHeader(needAuth, config.headers),
      withCredentials: true,
      params,
      ...config,
    });
  }

  put<T>({ needAuth = true, ...config }: RequestConfig) {
    return FetchService.operation<T>({
      method: "put",
      headers: this.setHeader(needAuth),
      withCredentials: true,
      ...config,
    });
  }

  delete<T>({ needAuth = true, ...config }: RequestConfig) {
    return FetchService.operation<T>({
      method: "delete",
      headers: this.setHeader(needAuth),
      withCredentials: true,
      ...config,
    });
  }

  // Return FormError in promise response type as well?
  private static async operation<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response = await axios(config);
      return _.has(response, "data.data") ? response.data.data : response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          // The client was given an error response (5xx, 4xx)
          const { error, data, description } = err.response.data as ApiError;
          throw new FormError(error, description, data);
        } else if (err.config) {
          // The client never received a response, and the config
          // was never left
          console.log("-----", "Unknown axios error", err.message);
        } else {
          // axios config errors
          console.log("-----", "Unknown axios error", err.message);
        }
      }
      console.log("-----", "Unknown error", err);
      throw new FormError("NETWORK_ERROR");
    }
  }
}

export default new FetchService();
